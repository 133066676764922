import { Fragment } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { ParallaxProvider } from 'react-scroll-parallax';
import 'dotenv/config';
import { transitions, positions, types, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import { GoogleOAuthProvider } from '@react-oauth/google';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
const options = {
    position: positions.TOP_CENTER,
    timeout: 10000,
    transition: transitions.SCALE,
    containerStyle: {
        zIndex: 100
      }
}

root.render(
    <GoogleOAuthProvider clientId="435327296131-8kb194sg2mo98jo55abniabhi38oc5f4.apps.googleusercontent.com">
        <Fragment>
            <ParallaxProvider>
                <AlertProvider template={AlertTemplate} {...options}>
                    <App />
                </AlertProvider>
            </ParallaxProvider>
        </Fragment>
    </GoogleOAuthProvider>
);