import React from 'react';
import HeaderOne from './header/HeaderOne';
import GridLoader from "react-spinners/GridLoader";
import { useGlobalContext } from '../context/global_context';
import SidePanel from '../pages/homepages/sidePanel';
import ActionPanel from '../pages/homepages/actionPanel';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import FooterOne from './footer/FooterOne';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from '@material-ui/core/Button';
import Photo from '../components/photos/Photo';

const LayoutAfterLogin = ({ children }) => {
    const {
        user,
        path,
        loading,
        isMobile,
        generatedPhotoOpen,
        updateGeneratedPhotoOpen
    } = useGlobalContext();

    const useStyles = makeStyles({
        fullScreenContentAreaWrapper: {
            position: 'absolute',
            top: 70,
            left: '11.9%',
            background: '#fff',
            width: '87%',
            height: 'auto',
            overflow: 'scroll',
            direction: 'column',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyItems: 'center'
        },
        mobileFullScreenContentAreaWrapper: {
            position: 'absolute',
            top: 70,
            left: 0,
            background: '#fff',
            width: '100%',
            bottom: 220,
            overflowY: 'scroll',
            direction: 'column',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyItems: 'center'
        },
        halfScreenContentAreaWrapper: {
            position: 'absolute',
            top: 70,
            left: '42%',
            bottom: 0,
            background: '#fff',
            // padding: '8px',
            width: '60%',
            height: 'auto',
            overflow: 'scroll',
            direction: 'column',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyItems: 'center'
        }
    });

    const classes = useStyles();

    const handleGeneratedPhotoClose = () => {
        updateGeneratedPhotoOpen(false);
    }

    let outputPanelClass = classes.halfScreenContentAreaWrapper;
    if(isMobile === true) {
        outputPanelClass = classes.mobileFullScreenContentAreaWrapper
    } else if(path === '/home' || path === '/my-profile') {
        outputPanelClass = classes.fullScreenContentAreaWrapper
    }

    return (
        <main>
            <HeaderOne />
            <SidePanel />
            { (path === '/make-my-model' 
                || path === '/hairstyle-try-on'
                || path === '/hairstyle-try-on') && <ActionPanel /> }
            
            <Box className={outputPanelClass}>
                {children}
                <FooterOne />

            </Box>

            {loading == true && <div className={loading ? 'parentDisable' : ''} width="100%">
                <div className='overlay-box'>
                    <GridLoader
                        color='#cc01ff'
                        loading={loading}
                        size={20} />
                </div>
            </div>}
            <Dialog maxWidth='xs' fullWidth={true} className={`sessionDialog`} open={generatedPhotoOpen} onClose={handleGeneratedPhotoClose}>
                <DialogTitle><b className='title' style={{ color: 'blue' }}>Created Photo</b></DialogTitle>
                <DialogContent>
                    <div className="edu-feature-list color-variation-1">
                        <div style={{ minWidth: '100%' }} className="content">
                            <div >
                                <div className="row" style={{
                                    display: 'block',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    {(user.customPhoto && Object.keys(user.customPhoto).length > 0) ? <Photo sharePresent={user.customPhoto.sharePresent} modelPrompt={user.customPhoto.modelPrompt} createdBy={user.customPhoto.createdBy} photoId={user.customPhoto._id} imageSDLink={user.customPhoto.imageSDLink} visibleForAll={user.customPhoto.visibleForAll} reusePresent="false" likePresent="false" themeKey={user.customPhoto?.theme} />
                                    : user.themePhoto && Object.keys(user.themePhoto).length > 0 && <Photo sharePresent={user.themePhoto.sharePresent} modelPrompt={user.themePhoto.modelPrompt} createdBy={user.themePhoto.createdBy} photoId={user.themePhoto._id} imageSDLink={user.themePhoto.imageSDLink} visibleForAll={user.themePhoto.visibleForAll} reusePresent="false" likePresent="false" themeKey={user.themePhoto?.theme}/>
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button className='edu-btn btn-small' onClick={handleGeneratedPhotoClose}
                        color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </main>
    )
}
export default LayoutAfterLogin;