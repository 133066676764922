import React, { useEffect, useContext, useReducer } from 'react';
import reducer from '../reducers/global_reducer';
import { backendCall } from '../utils/http';
import Razorpay from 'razorpay';
import { useAlert } from 'react-alert'
import AWS from 'aws-sdk';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import Resizer from "react-image-file-resizer";

const rzr_instance = new Razorpay({
    key_id: 'rzp_live_r1rtOe03wGDvsJ',
    key_secret: 'shmPSZeTvM5bMNRta8Ep1UYx',
});

const user = JSON.parse(localStorage.getItem('tbcs_user')) || {};
const path = JSON.parse(localStorage.getItem('tbcs_path')) || "/";
const uploads = JSON.parse(localStorage.getItem('tbcs_uploads')) || [];

const defaultState = {
    user,
    path,
    payment: {},
    loading: false,
    activeMenu: 'hairstyle',
    activeSubMenuGender: 'female',
    isMobile: window.innerWidth < 800,
    demo: {
        action: "face-creation",
        recentUploadsDisplay: true,
        width: 512,
        height: 512,
        hairCustomisation:'black',
        skinTone: 5
    },
    uploads,
    needLogin: false
}

const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
);

const GlobalContext = React.createContext();

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      "PNG",
      1,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });

export const GlobalProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, defaultState);
    const alert = useAlert()

    useEffect(() => {
        window.addEventListener("resize", () => {
            const ismobile = window.innerWidth < 800;
            if (ismobile !== state.isMobile) {
                dispatch({ type: 'UPDATE_ISMOBILE', payload: ismobile })
            }
        }, false);
    }, [state.isMobile]);

    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    };

    useEffect(() => {
        loadScript("https://checkout.razorpay.com/v1/checkout.js");
    });

    const log = async ({ screenType, action, generatedPhoto }) => {
        if (user.email !== "hello7@gmail.com"
            && user.email !== "navita@gmail.com"
            && user.email !== "shivam.pareek2002@gmail.com") {

            if(action === "PHOTO_LIKED") {
                alert.removeAll();
                alert.success("Thanks for liking this photo");
            } else if (action === "PHOTO_DISLIKED") {
                alert.removeAll();
                alert.error("Thanks for your feedback. We will improve our image generation.")
            }

            try {
                await backendCall({
                    "method": "POST",
                    path: '/Log',
                    "body": {
                        email: user.email !== undefined ? user.email : "NOT LOGGED IN",
                        screenType,
                        action,
                        generatedPhoto
                    },
                    admin: true
                });
            } catch { }
        }
    }

    const fetchUser = async () => {
        try {
            const response = await backendCall({
                "method": "GET",
                path: '/Candidate',
                token: state.user && state.user.token
            });

            if (response && response.success === true && response.data && response.data.user) {
                dispatch({ type: 'UPDATE_USER', payload: response.data.user });
            }
        } catch (ex) { }
    }

    const updateRegisterEmail = (e) => {
        const value = e.target.value
        dispatch({ type: 'UPDATE_REGISTER_EMAIL', payload: value.replace(' ', '') })
    }

    const updatePassword = (e) => {
        const value = e.target.value;
        dispatch({ type: 'UPDATE_PASSWORD', payload: value })
    }

    const googleSignIn = useGoogleLogin({
        onSuccess: (codeResponse) => {
            localStorage.removeItem("tbcs_user");
            localStorage.removeItem("tbcs_path");
            if (codeResponse) {
                axios
                    .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`, {
                        headers: {
                            Authorization: `Bearer ${codeResponse.access_token}`,
                            Accept: 'application/json'
                        }
                    })
                    .then(async (res) => {
                        dispatch({ type: 'SET_LOADER', payload: true });

                        // Valid User, thus add entry in the backend
                        const userResponse = await backendCall({
                            "method": "POST",
                            "path": "/Candidate/get-user",
                            "body": {
                                "email": res.data.email,
                                "path": "/",
                                "app": "instalooksai"
                            },
                            admin: true
                        });

                        if (userResponse.success == true) {
                            dispatch({
                                type: "LOGIN_SUCCESS", payload: {
                                    user: {
                                        ...userResponse.data.user,
                                        loginType: 'google'
                                    },
                                    path: state.path ? state.path : "/hairstyle-try-on"
                                }
                            });                          

                            setTimeout(() => {
                                // log({ screenType: "Login Screen", action: "Google Login" });
                                window.location.href = state.path ? state.path : "/hairstyle-try-on";
                            }, 1000);
                            alert.removeAll();
                            alert.success("Logged In Successfully!! Generate Stunning Photos By AI.");
                        } else {
                            alert.removeAll();
                            alert.error("Login Failed!!\nCheck email and minimum 6 letters password.");
                        }
                        dispatch({ type: 'SET_LOADER', payload: false });
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch({ type: 'SET_LOADER', payload: false });
                    });
            }
        },
        onError: (error) => {
            alert.removeAll();
            alert.error('Login Failed ', error);
            dispatch({ type: 'SET_LOADER', payload: false });
        }
    });

    const submit = async (e) => {
        try {
            if (!state.email || !state.password || state.email.length < 4 || state.password.length < 6) {
                alert.show("Provide valid email and minimum 6 length password");
                return;
            }

            var mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if (!state.email.match(mailFormat)) {
                alert.show("Provide valid email and minimum 6 length password");
                return;
            }

            dispatch({ type: 'SET_LOADER', payload: true });

            localStorage.removeItem("tbcs_user");
            localStorage.removeItem("tbcs_path");

            e.preventDefault();

            let path = "/Candidate/verifyPassword"

            const response = await backendCall({
                "method": "POST",
                "path": path,
                "body": {
                    "email": state.email,
                    "password": state.password,
                    "path": "/hairstyle-try-on",
                    "app": "instalooksai"
                },
                admin: true
            });

            if (response.success == true) {

                dispatch({
                    type: "LOGIN_SUCCESS", payload: {
                        user: {
                            ...response.data.user
                        },
                        path: "/hairstyle-try-on"
                    }
                });

                setTimeout(() => {
                    // log({ screenType: "Login Screen", action: "Email/Password Login" });
                    window.location.href = "/hairstyle-try-on";
                }, 1000);
                alert.removeAll();
                alert.success("Logged In Successfully!! Generate Stunning Photos By AI.");
            } else {
                alert.removeAll();
                alert.error("Login Failed!!\nCheck email and minimum 6 letters password.");
            }
            dispatch({ type: 'SET_LOADER', payload: false });
        } catch (ex) {
            alert.removeAll();
            alert.error("Login Failed!!\nCheck email and minimum 6 letters password.");
            dispatch({ type: 'SET_LOADER', payload: false });
        }
    }

    const pay = async (price, duration, membershipType, photoCredits = 0) => {
        dispatch({ type: 'SET_LOADER', payload: true });

        try {
            const orderRequestParams = {};
            orderRequestParams.receipt = Date.now().toString();
            orderRequestParams.currency = (price.indexOf('₹') >= 0) ? 'INR' : 'USD';
            orderRequestParams.amount = parseInt(price.replace('₹', '').replace('$', '') + '00');
            orderRequestParams.duration = duration;
            alert.removeAll();
            alert.info("Initiating Payment!!\nPlease wait...");

            // Create an Order
            const order = await backendCall({
                "method": "POST",
                "path": "/Billing/order",
                body: orderRequestParams,
                token: state.user && state.user.token
            });

            if (!order.success || order.success == false) {
                dispatch({ type: 'SET_LOADER', payload: false });
                dispatch({ type: 'PAYMENT_FAILED', payload: { message: "Unable to created an order" } });
                alert.removeAll();
                alert.error("Unable to created a payment order. Kindly retry after sometime or contact support.");
                return;
            }

            // Save Billing info in Our Backend
            const billing = await backendCall({
                "method": "POST",
                "path": "/Billing",
                "body": {
                    orderId: order.data.id,
                    orderType: (membershipType === 'ANY') ? "model" : "membership",
                    amount: orderRequestParams.amount,
                    currency: orderRequestParams.currency,
                    duration: orderRequestParams.duration,
                    membershipType: membershipType,
                    paymentStatus: "created",
                    gatewayUsed: "razorpay"
                },
                token: state.user && state.user.token
            });

            if (!billing.success || billing.success == false) {
                dispatch({ type: 'SET_LOADER', payload: false });
                dispatch({ type: 'PAYMENT_FAILED', payload: { message: "Unable to creat billing at backend" } });
                alert.removeAll();
                alert.error("Unable to creat a payment billing. Kindly retry after sometime or contact support.");
                return;
            }

            dispatch({ type: 'SET_LOADER', payload: false });

            // Open Razorpay
            const options = {
                key: 'rzp_live_r1rtOe03wGDvsJ',
                currency: orderRequestParams.currency,
                amount: orderRequestParams.amount,
                name: "InstaLooksAI",
                description: (membershipType === 'ANY') ? "Model" : membershipType + " Membership",
                order_id: order.data.id,
                handler: async function (response) {
                    if (response.razorpay_payment_id && response.razorpay_order_id) {
                        if (membershipType === 'ANY') {
                            alert.removeAll();
                            alert.success("Payment Successful!! Let's create your model.");
                            // log({ screenType: "Pricing Screen", action: "Buy Model Credits" });
                        } else {
                            alert.removeAll();
                            alert.success("Payment Successful!! Let's create stunning photos.");
                            // log({ screenType: "Pricing Screen", action: "Buy Photo Credits" });
                        }

                        const data = {
                            orderCreationId: order.data.id,
                            billingId: billing.data._id,
                            duration,
                            membershipType,
                            razorpayPaymentId: response.razorpay_payment_id,
                            razorpayOrderId: response.razorpay_order_id,
                            razorpaySignature: response.razorpay_signature,
                            photoCredits
                        };

                        // Inform backend for successful payment
                        const billingResponse = await backendCall({
                            "method": "POST",
                            "path": "/Billing/success",
                            body: data,
                            token: state.user && state.user.token
                        });

                        // Update membership data
                        if (billingResponse && billingResponse.success === true && billingResponse.data) {
                            dispatch({ type: 'SET_MEMBERSHIP_DETAILS', payload: billingResponse.data });
                            window.location.href = state.path ? state.path : '/';
                        } else {
                            alert.removeAll();
                            alert.info("Payment Successful but record not updated in the backend. Kindly Contact Support.")
                        }

                    } else {
                        alert.removeAll();
                        alert.error("Payment Failed!! Contact Support at +91 7093003840");
                    }
                },
                prefill: {
                    name:"",
                    contact: "+917093003840",
                    email: state.user.email
                },
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        } catch (ex) {
            dispatch({ type: 'SET_LOADER', payload: false });
            alert.removeAll();
            alert.error("Payment Failed!! Contact Support at +91 7093003840");
        }
    }

    const logout = (e) => {
        e.preventDefault();
        if (state.user.loginType === 'google') {
            googleLogout();
        }
        dispatch({ type: 'LOGOUT' });
        window.location.href = '/';
        alert.removeAll();
        alert.success("Logged Out Successfully!!");
    }

    const updatePath = (newPath) => {
        dispatch({ type: "UPDATE_PATH", payload: newPath });
    }

    const updatedActiveMenu = (newMenu) => {
        dispatch({ type: 'UPDATE_ACTIVE_MENU', payload: newMenu })
    }

    const updatedActiveSubMenu = (newMenu) => {
        dispatch({ type: 'UPDATE_ACTIVE_SUB_MENU', payload: newMenu });
    }

    const deletePhoto = async (photoId) => {
        alert.info("Deleting Selected Photo!!");
        dispatch({ type: 'SET_LOADER', payload: true });

        if (photoId) {
            try {
                const deleteResponse = await backendCall({
                    "method": "POST",
                    "path": "/Photo/delete",
                    "body": {
                        "photoId": photoId
                    },
                    token: state.user && state.user.token
                });

                alert.removeAll();
                if (deleteResponse && deleteResponse.success === true) {
                    dispatch({ type: 'DELETE_PHOTO', payload: photoId })
                    dispatch({ type: 'SET_LOADER', payload: false });
                    alert.success("Photo Successfully Deleted.");
                    updateGeneratedPhotoOpen(false);
                } else {
                    dispatch({ type: 'SET_LOADER', payload: false });
                    alert.error("Failed to delete this photo.")
                }
            } catch (ex) {
                dispatch({ type: 'SET_LOADER', payload: false });
                alert.error("Failed to delete this photo.")
            }
        } else {
            dispatch({ type: 'SET_LOADER', payload: false });
            alert.error("Failed to delete this photo.")
        }
    }

  
    const updateGeneratedPhotoOpen = (ifOpen) => {
        dispatch({ type: 'UPDATE_GENERATED_PHOTO_DIALOG', payload: ifOpen });
    }

    const onAccountTypeChanged = async (e) => {
        const accountType = e.target.value;
        dispatch({ type: 'SET_LOADER', payload: true });

        if (accountType) {
            try {
                const response = await backendCall({
                    "method": "PATCH",
                    "path": "/Candidate/",
                    "body": {
                        accountType
                    },
                    token: state.user && state.user.token
                });

                alert.removeAll();
                if (response && response.success === true) {
                    dispatch({ type: 'UPDATE_ACCOUNT_TYPE', payload: accountType })
                    dispatch({ type: 'SET_LOADER', payload: false });
                    alert.success("Account type is successfully set to " + accountType + ".");
                } else {
                    dispatch({ type: 'SET_LOADER', payload: false });
                    alert.error("Failed to change account type.");
                }
            } catch (ex) {
                dispatch({ type: 'SET_LOADER', payload: false });
                alert.error("Failed to change account type.")
            }
        } else {
            dispatch({ type: 'SET_LOADER', payload: false });
            alert.error("Failed to change account type.")
        }
    }

    const setLoader = (status) => {
        dispatch({ type: 'SET_LOADER', payload: status });
    }

    const getPhotoDetails = async (id) => {
        dispatch({ type: 'SET_LOADER', payload: true });
        dispatch({ type: 'CLEAR_PHOTO_PAGE' });

        try {
            const response = await backendCall({
                "method": "GET",
                "path": "/Photo/" + id,
                admin: true
            });

            if (response && response.success === true && response.photo) {
                dispatch({ type: 'SET_LOADER', payload: false });
                dispatch({ type: 'UPDATE_PHOTO_PAGE', payload: response.photo });
            } else {
                dispatch({ type: 'SET_LOADER', payload: false });
                dispatch({ type: 'CLEAR_PHOTO_PAGE' });
                alert.error("Failed to fetch the photo.")
            }
        } catch (ex) {
            dispatch({ type: 'SET_LOADER', payload: false });
            dispatch({ type: 'CLEAR_PHOTO_PAGE' });
            alert.error("Failed to fetch the photo.")
        }
    }
    
    const onFaceImageUploaded = async(e) => {
        dispatch({ type: 'SET_LOADER', payload: true });
        dispatch({ type: 'SET_HAIR_CUT', payload: undefined });
        dispatch({ type: 'SET_HAIR_CUSTOMISATION', payload: undefined });
        dispatch({ type: 'SET_DEMO_GENERATED_PHOTOS_NAME', payload: undefined });
        dispatch({ type: 'SET_DEMO_GENERATED_PHOTOS', payload: undefined });

        dispatch({ type: 'SET_SELECTED_PHOTO'});

        var file = Object.values(document.getElementById("face-photo").files)[0];

        // const MaxSizeInBytes = (1048576 * 5); //5MB
        // if (file.size > MaxSizeInBytes) {
        //     dispatch({ type: 'SET_LOADER', payload: false });
        //     document.getElementById("face-photo").value = null;
        //     dispatch({ type: 'UPDATE_FACE_PHOTO_LINK', payload: { filename: undefined, link: undefined } });
        //     alert.error("The file size must be no more than " + parseInt(MaxSizeInBytes / 1024 / 1024) + "MB. Reload valid sized images.");
        //     return;
        // } else {
            // resize photo to 512x512
            const resizedImage = await resizeFile(file);

            // Upload it to AWS and save link at demo/facePhotoLink
            try {
                const response = await backendCall({
                    "method": "GET",
                    "path": "/Document/credentials",
                    token: state.user && state.user.token
                });

                if (response && response.success === true && response.data) {
                    AWS.config.update({
                        accessKeyId: response.data.accessKeyId,
                        secretAccessKey: response.data.secretAccessKey,
                        region: response.data.region
                    });
    
                    const s3 = new AWS.S3();
                    const params = {
                        Bucket: response.data.Bucket,
                        Key: state.user.email + "_" + Date.now() + "_" + resizedImage.name.replaceAll(' ', '-'),
                        Body: resizedImage,
                        ACL: response.data.ACL
                    };

                    s3.upload(params, async (err, data) => {
                        if (err) {
                            console.log(err);
                            dispatch({ type: 'SET_LOADER', payload: false });
                            alert.error("Error while uploading face photo. " + err.message);
                        } else {
                            dispatch({ type: 'SET_LOADER', payload: false });
                            dispatch({ 
                                type: 'UPDATE_FACE_PHOTO_LINK', 
                                payload: { 
                                    filename: params.Key, 
                                    link: data.Location
                                } });
                            alert.success("Images uploaded successfully.");
                        }
                    });
                } else {
                    dispatch({ type: 'SET_LOADER', payload: false });
                    alert.error("Login to use this feature!!");
                    console.log("Login to use this feature!!");
                }
            }catch(ex){
                dispatch({ type: 'SET_LOADER', payload: false });
                alert.error("Error while uploading face photo. " + ex.message);
            }
        // }
    }

    const removeFacePhoto = (e) => {
        document.getElementById("face-photo").value = null;
        dispatch({ type: 'UPDATE_FACE_PHOTO_LINK', payload: { filename: undefined, link: undefined } });
        dispatch({ type: 'SET_SELECTED_PHOTO'});
        dispatch({ type: 'SET_DEMO_GENERATED_PHOTOS', payload: undefined });
    }

    const updateDemoCount = (e) => {
        const value = e.target.value;
        dispatch({ type: 'SET_DEMO_COUNT', payload: value })
    }

    const updateDemoWidth = (e) => {
        const value = e.target.value;
        dispatch({ type: 'SET_DEMO_WIDTH', payload: value })

        let height = undefined;

        if(state.demo.aspectRatio === "SQUARE") {
            height = value; 
        } else if(state.demo.aspectRatio === "PORTRAIT") {
            height = parseInt(value*3/2); 
        } else if(state.demo.aspectRatio === "LANDSCAPE") {
            height = parseInt(value*2/3);
        }

        if(height !== undefined) {
            dispatch({ type: 'SET_DEMO_HEIGHT', payload: height })
        }
    }

    const updateDemoHeight = (e) => {
        const value = e.target.value;
        dispatch({ type: 'SET_DEMO_HEIGHT', payload: value })

        let width = undefined;

        if(state.demo.aspectRatio === "SQUARE") {
            width = value; 
        } else if(state.demo.aspectRatio === "PORTRAIT") {
            width = parseInt(value*2/3); 
        } else if(state.demo.aspectRatio === "LANDSCAPE") {
            width = parseInt(value*3/2);
        }

        if(width !== undefined) {
            dispatch({ type: 'SET_DEMO_WIDTH', payload: width })
        }
    }

    const updatedTryOnGender = async(gender) => {
        dispatch({ type: 'SET_ACTIVE_SUB_MENU_GENDER', payload: gender });
    }

    const updatedHairCut = async(haircut) => {
        // TODO: Add Log for generating hair-cut photo
        // TODO: Add Log for generating hair-cut photo with face photo

        dispatch({ type: 'SET_HAIR_CUT', payload: haircut });

        if(state.demo.facePhotoLink) {
            // log({ screenType: "CUSTOM_PHOTO", action: "HAIR_CUT_WITH_FACE" });
            await generateNewLook({haircut});
        } else {
            // log({ screenType: "CUSTOM_PHOTO", action: "HAIR_CUT_WITHOUT_FACE" });
            // dispatch({ type: 'SET_DEMO_GENERATED_PHOTOS_NAME', payload: haircut });
            alert.error("Upload the face photo through 'Settings' menu, to see this look on you!!");
        }
    }

    const updatedHairCustomisation = async(customisation) => {
        // TODO: Add Log for generating hair-cut photo
        // TODO: Add Log for generating hair-cut photo with face photo
        dispatch({ type: 'SET_HAIR_CUSTOMISATION', payload: customisation });

        // Call face swap for hair customisation
        if(state.demo.facePhotoLink) {
            // log({ screenType: "CUSTOM_PHOTO", action: "HAIR_CUSTOMISATION_WITH_FACE" });
            await generateNewLook({customisation});
        } else {
            // log({ screenType: "TRY_ON", action: "HAIR_CUSTOMISATION_WITHOUT_FACE" });
            // dispatch({ type: 'SET_DEMO_GENERATED_PHOTOS_NAME', payload: customisation });
            alert.error("Upload the face photo through 'Settings' menu, to see this look on you!!");
        }
    }

    const generateNewLook = async({haircut, customisation}) => {
        // log({ screenType: "CUSTOM_PHOTO", action: "HAIR_CUT_WITH_FACE" });
        alert.removeAll();

        if(!state.demo.facePhotoLink) {
            alert.info("Please upload your face photo through Settings.");
            return;
        }

        if(!haircut) {
            haircut = state.activeHairCut 
        }

        if(!customisation) {
            customisation = state.demo.hairCustomisation 
        }

        try {
            alert.info("Preparing your new look image.");
            dispatch({ type: 'SET_LOADER', payload: true });
            // dispatch({ type: 'SET_API_CALL_DURATION', payload: undefined });
            let waitTime = 0;

            // let startTime = new Date();
            const response = await backendCall({
                "method": "POST",
                "path": '/Photo/new-look-generation',
                "body": {
                    "hairstyle": haircut,
                    "customisation": customisation,
                    "new_face_image_name": state.demo.facePhotoLinkFilename,
                    "width":  '512',
                    "height":  '512',
                    "gender": state.activeSubMenuGender,
                    "skinTone": state.demo.skinTone,
                    "app": "instalooksai",
                    "device": "web"
                },
                token: state.user && state.user.token
            });

            if (response && response.success === true && response.request_id) {
                if(response.queuePosition != undefined) {
                    alert.removeAll();
                    waitTime = (response.queuePosition+1)*10;
                    alert.info("Showing your new look in ~" + waitTime + " seconds.")
                }
                let response2 = {};
                for(let i = 0; i<20; i++) {
                    await delay(2000);
                    response2  = await backendCall({
                        "method": "POST",
                        "path": '/Photo/get-photo',
                        "body": {
                            "request_id": response.request_id
                        },
                        token: state.user && state.user.token
                    });

                    if (response2 && response2.success === true && response2.imageAWSLink) {
                        alert.removeAll();
                        alert.info("Your New Look Is Ready!!")

                        dispatch({ type: 'SET_LOADER', payload: false });
                        dispatch({ type: 'SET_DEMO_GENERATED_PHOTOS', payload: response2.imageAWSLink });
                        dispatch({ type: 'DECREMENT_PHOTO_CREDIT' });
                        // log({ screenType: "HAIR_CUT", action: "GENERATE_PHOTO", generatedPhoto: response2.photos });
                        break;
                    } else if(response2 && response2.success === true && response2.queuePosition != undefined) {
                        if(waitTime != (response2.queuePosition+1)*10) {
                            waitTime = (response2.queuePosition+1)*10
                            alert.removeAll();
                            alert.info("Showing your new look in ~" + waitTime + " seconds.")
                        }
                    } else {
                        alert.removeAll();
                        alert.error("Failed to create image. Refresh this page and try again.")
                    }
                }
            }

            if (!response || (response.success === false && response.error === 'Membership with photo credits required for this feature.')) {
                dispatch({ type: 'SET_LOADER', payload: false });
                alert.removeAll();
                alert.error("Need Photo Credits To Generate Photos.")
            } else if (!response || response.success === false || !response.success) {
                dispatch({ type: 'SET_LOADER', payload: false });
                console.log("response.error: ", response.error);
                alert.removeAll();
                alert.error("Failed to create image. Refresh this page and try again.")
            }

            // let endTime = new Date();
            // let timeElapsed = endTime - startTime;
            // if(parseInt(timeElapsed)) {
            //     dispatch({ type: 'SET_API_CALL_DURATION', payload: timeElapsed/1000 });
            // }
        }catch(ex) {
            
            dispatch({ type: 'SET_LOADER', payload: false });
            alert.removeAll();
            alert.error("Error while generating photo. " + ex.message);
        }
    }

    const updateAspectRatio = async(aspectRatio) => {
        dispatch({ type: 'UPDATE_ASPECT_RATIO', payload:aspectRatio });
    }

    const setNeedLogin = async(value) => {
        dispatch({ type: 'SET_NEED_LOGIN', payload:value });
    }

    const selectUploadedImage = async(payload) => {
        dispatch({ type: 'SET_HAIR_CUT', payload: undefined });
        dispatch({ type: 'SET_HAIR_CUSTOMISATION', payload: undefined });
        dispatch({ type: 'SET_SELECTED_PHOTO', payload });
        dispatch({ type: 'SET_DEMO_GENERATED_PHOTOS', payload: undefined });
    }

    const setRecentUploadsDisplay = (e) => {
        const value = e.target.checked;
        dispatch({ type: 'SET_RECENT_UPLOADS_DISPLAY', payload: value })
        dispatch({ type: 'SET_SELECTED_PHOTO' });
    }

    const updateSettingsChecked = (e) => {
        dispatch({ type: 'UPDATE_SETTINGS_CHECKED', payload: true });
    }

    const setSkinTone = (newSkinTone) => {
        dispatch({ type: 'UPDATE_SKIN_TONE', payload: newSkinTone });
    }

    const updatedHairColor = async(customisation) => {
        dispatch({ type: 'SET_HAIR_CUSTOMISATION', payload: customisation });
    }

    const updatedBeard = async(customisation) => {
        dispatch({ type: 'SET_HAIR_CUSTOMISATION', payload: customisation });
    }
    
    return (
        <GlobalContext.Provider
            value={{
                ...state,
                updateRegisterEmail,
                updatePassword,
                submit,
                pay,
                logout,
                updatePath,
                updatedActiveMenu,
                deletePhoto,
                updateGeneratedPhotoOpen,
                fetchUser,
                onAccountTypeChanged,
                googleSignIn,
                setLoader,
                getPhotoDetails,
                log,
                onFaceImageUploaded,
                removeFacePhoto,
                updateDemoCount,
                updateDemoWidth,
                updateDemoHeight,
                updatedActiveSubMenu,
                updatedTryOnGender,
                updatedHairCut,
                updatedHairCustomisation,
                updateAspectRatio,
                setNeedLogin,
                selectUploadedImage,
                setRecentUploadsDisplay,
                updateSettingsChecked,
                setSkinTone,
                updatedHairColor,
                updatedBeard
            }}
        >
            {children}
        </GlobalContext.Provider>
    )
}

export const useGlobalContext = () => {
    return useContext(GlobalContext)
}