import React from 'react';
import 'dotenv/config';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/scrolltotop/ScrollToTop';
import AboutUs from './pages/innerpages/AboutUs';
import ContactUs from './pages/innerpages/ContactUs';
import Faq from './pages/innerpages/Faq';
import HomeFour from './pages/homepages/HomeFour';
import PhotoPage from './pages/innerpages/PhotoPage'
import PrivacyPolicy from './pages/innerpages/PrivacyPolicy';
import Error from './pages/innerpages/Error';
import Pricing from './pages/innerpages/Pricing';
import TermsAndConditions from './pages/innerpages/TermsAndConditions';
import CancellationRefundPolicy from './pages/innerpages/CancellationRefundPolicy';
import { GlobalProvider } from './context/global_context';

// Import Css Here 
import './assets/scss/style.scss';
import Profile from './pages/innerpages/Profile';
import TryOn from './pages/innerpages/TryOn';

function App() {
    return (
        <GlobalProvider>
        <Router>
            <ScrollToTop>
                <Routes>
                    <Route exact path='/' element={<HomeFour/>}/>
                    <Route exact path='/home' element={<HomeFour/>}/>
                    
                    <Route exact path={`${'/about-us'}`} element={<AboutUs/>}/>
                    <Route exact path={`${'/contact-us'}`} element={<ContactUs/>}/>

                    <Route exact path={`${'/hairstyle-try-on'}`} element={<TryOn/>}/>

                    <Route exact path={`${'/faq'}`} element={<Faq/>}/>
                    <Route exact path={`${'/privacy-policy'}`} element={<PrivacyPolicy/>}/>
                    <Route exact path={`${'/my-profile'}`} element={<Profile/>}/>

                    <Route exact path={`${'/pricing'}`} element={<Pricing/>} />
                    <Route exact path={`${'/terms-and-conditions'}`} element={<TermsAndConditions/>} />
                    <Route exact path={`${'/cancellation-refund-policy'}`} element={<CancellationRefundPolicy/>} />

                    <Route exact path='/photo/:id' element={<PhotoPage/>}/>

                    <Route path='*' element={<Error/>}/>
                </Routes>
            </ScrollToTop>
        </Router>
        </GlobalProvider>
    );
}

export default App;
