import React from 'react';
import { useGlobalContext } from '../../context/global_context';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player'

const BannerFour = () => {
    const {
        user,
        isMobile,
        setNeedLogin
    } = useGlobalContext();

    return (
        <div className="slider-area banner-style-4 bg-image">
            <div className="wrapper d-flex align-items-center home-four-banner-wrapper">
                <div className="container eduvibe-animated-shape">
                    <div className="row align-items-center">
                        <div className="col-lg-6 order-2 order-lg-1 col-sm-12">
                            <div className="inner mt_md--10 mt_sm--10">
                                <div className="content text-start">
                                    <span className="pre-title">We Understand - Change Is Difficult!!</span>
                                    <h5>Our Technology Enables Magic Mirror For You</h5>
                                    <h4 style={{color: "#f38b01"}} className="title">Different Hairstyles<br />Virtual Try-On</h4>
                                    <span className="description"><b>Within Seconds!!</b></span>
                                    <br />
                                    <span className="description" style={{color: "#f38b01"}} ><b><i className="icon-Star"></i> Using Just 1 Photo <i className="icon-Star"></i></b></span>
                                    <br />
                                    <button onClick={() => setNeedLogin(true)} className={`edu-btn btn-medium btn-secondary left-icon left-btn rn-btn`}>
                                        Signup & Get 2 Free Photos <i className="icon-arrow-right-line-right"></i>
                                    </button>
                                    <br/>
                                    <p className="mt--40 mt_md--20 mt_sm--20" style={{fontSize:'13px'}}>*No credit card required.</p>
                                    <br/>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2 col-sm-12">
                            <div className="banner-image">
                                <div className="thumbnail" style={{boxShadow: '2px 2px 2px 2px #e6e6e6'}}>
                                    {/* <img src="/images/banner/banner-04/1.png" alt="Banner Images" /> */}
                                    <ReactPlayer width={(isMobile == false) ? "640px" : "320px"} height={(isMobile == false) ? "360px" : "200px"} controls muted={true} url='https://www.youtube.com/watch?v=hDsS-IGYkPQ' />
                                </div>
                                <Link to='' onClick={() => setNeedLogin(true)}>
                                <div className="learner-badge">
                                    <div className="badge-inner">
                                            <span className="viewer">Using</span>
                                            <span className="viewer">Just 1</span>
                                            <span className="viewer">Selfie</span>
                                    </div>
                                </div>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                        <div className="shape-image shape-image-1">
                            <img src="/images/shapes/shape-05-03.png" alt="Shape Thumb" />
                        </div>
                        <div className="shape-image shape-image-2">
                            <img src="/images/shapes/shape-05-04.png" alt="Shape Thumb" />
                        </div>
                        <div className="shape-image shape-image-3">
                            <img src="/images/shapes/shape-19-04.png" alt="Shape Thumb" />
                        </div>
                        <div className="shape-image shape-image-4">
                            <img src="/images/shapes/shape-01-04.png" alt="Shape Thumb" />
                        </div>
                        <div className="shape-image shape-image-5">
                            <img src="/images/shapes/shape-01-03.png" alt="Shape Thumb" />
                        </div>
                        <div className="shape-image shape-image-6">
                            <img src="/images/shapes/shape-06.png" alt="Shape Thumb" />
                        </div>
                    </div>
                </div>

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-left">
                        <img src="/images/shapes/shape-13-08.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-right">
                        <img src="/images/shapes/shape-13-09.png" alt="Shape Thumb" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BannerFour;