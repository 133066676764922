import React from 'react';
import HeaderOne from './header/HeaderOne';
import GridLoader from "react-spinners/GridLoader";
import { useGlobalContext } from '../context/global_context';
import FooterOne from './footer/FooterOne';

const Layout = ({ children }) => {
    const {
        loading
    } = useGlobalContext();

    return (
        <main>
            <HeaderOne />
            {children}
            <FooterOne />
            <div className={loading ? 'parentDisable' : ''} width="100%">
                <div className='overlay-box'>
                    <GridLoader
                        color='#cc01ff'
                        loading={loading}
                        size={20} />
                </div>
            </div>
        </main>
    )
}
export default Layout;