import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SEO from '../../common/SEO';
import LayoutAfterLogin from '../../common/LayoutAfterLogin';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import { useGlobalContext } from '../../context/global_context';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import Grid from '@material-ui/core/Grid'
import ReactPlayer from 'react-player'

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const inIndia = timeZone.indexOf('Calcutta') >= 0;

const hairstyles = require('../../utils/hairstyles.json');

async function downloadBinaryPhoto(imageBinary) {
    try {
        // Create a temporary anchor element
        // const url = window.URL.createObjectURL("data:image/png;base64," + imageBinary);
        const link = document.createElement('a');
        link.href = "data:image/png;base64," + imageBinary;

        // Extract the filename from the URL
        const filename = "instaLooksAI_output_" + Date.now() + ".png";

        // Set the download attribute and filename
        link.setAttribute('download', filename);
        document.body.appendChild(link);

        // Simulate a click on the anchor element to start the download
        link.click();

        // Clean up the temporary anchor element
        link.parentNode.removeChild(link);
    } catch (ex) {
        console.log("Error in downloading an image", ex.message)
    }
};

async function downloadPhoto(imageUrl) {
    fetch(imageUrl, {
        "mode": "cors",
        "headers": {
            "Content-Type": "application/json",
            "access-control-allow-origin": "*",
            'Cache-Control': 'no-cache'
        }
    })
        .then(response => response.blob())
        .then(blob => {
            // Create a temporary anchor element
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;

            // Extract the filename from the URL
            const filename = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);

            // Set the download attribute and filename
            link.setAttribute('download', filename);
            document.body.appendChild(link);

            // Simulate a click on the anchor element to start the download
            link.click();

            // Clean up the temporary anchor element
            link.parentNode.removeChild(link);
        })
        .catch(error => {
            console.error('Error downloading image:', error);
        });
};

const useStyles = makeStyles({
    bottomBtn: {
        fontSize: '12px',
        fontWeight: 'bold',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    }
});

const TryOn = () => {
    const {
        demo,
        path,
        user,
        isMobile,
        updatePath,
        updatedActiveMenu,
        activeHairCut,
        log,
        fetchUser,
        pay
    } = useGlobalContext();

    const imageReference = useRef(null);
    const [images, setImages] = useState([]); // Use state to store the images

    if(demo.photos && demo.photos.length !== images.length ) {
        setImages(demo.photos);
    }
    
    useEffect(() => {
        if(imageReference && imageReference.current) {
            imageReference.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [images]);

    const classes = useStyles();

    if (path != '/hairstyle-try-on') {
        updatePath('/hairstyle-try-on');
    }

    const hairstyle = hairstyles[activeHairCut];

    return (
        <>
            <SEO title="Insta Photo AI | Custom Created Photo" />
            <LayoutAfterLogin>
                {isMobile === false && <BreadcrumbOne
                    title="Virtual Try-On"
                    parentUrl="Home"
                    currentUrl="AI Created Photos"
                />}
                {user && user.membership && user.membership.photosCredit <= 0 && <>
                    <div style={{ background: '#fef0e5', border: 'thick double #F86F03', margin: '10px', padding: '20px 60px' }}>
                        <b className="description">Your Photo Credit is Zero.</b>
                        <br />
                        <span className="description">Recharge now!!</span>
                        <div className="pricing-btn">
                            <button className={`edu-btn btn-small`} onClick={() => pay(inIndia ? '₹699' : '$9', "monthly", 'SILVER')}>
                                Buy 500 Credits at {inIndia ? '₹699' : '$9'} Now!!<i className="icon-arrow-right-line-right"></i>
                            </button>
                        </div>
                        <span>Or <u><a target="_blank" href="/pricing">Visit Pricing</a></u> to know all the plans.</span>

                    </div>
                </>}
                {user && user.membership && user.membership.photosCredit > 0 && <>
                    <div style={{ background: '#e2dcfa', border: 'thick double #7152E9', margin: '2.5px', padding: '5px 15px' }}>
                        <span className="description">Remaining Photo Credit: {user.membership.photosCredit}</span>
                    </div>
                </>}
                <br />
                <div className="col-12 row ">

                    <div className="col-lg-6 order-1">
                        {demo.photosName &&
                            demo.photosName.length > 0 &&
                            demo.facePhotoLink === undefined &&
                            <><ReactPlayer muted={true} light controls width="320px" height="180px" url='https://youtu.be/hDsS-IGYkPQ' />
                                <b>Experience Hairstyle within Seconds</b>
                                <br />
                                <span style={{ fontSize: '14px' }}>Step 1. Goto <u onClick={() => { updatedActiveMenu('settings'); fetchUser(); }}>Settings</u> to upload face-photo</span>
                                <br /><span style={{ fontSize: '14px' }}>Step 2. Select Hairstyle</span>
                                <br /><span style={{ fontSize: '14px' }}>Step 3. Select Customisations (haircolor/beard)</span>
                                <br />
                            </>
                        }
                        {demo.photos &&
                            demo.photos.map((photo, index) => {
                                return <>
                                    <span>{index+1}/{demo.photos.length}</span>
                                    <img style={{ border: '1px solid #000' }} 
                                    ref={(index === demo.photos.length - 1) ? imageReference: null}  
                                    src={photo} 
                                    alt="Generated Images" />
                                    <br />
                                    <button className="edu-btn btn-small" onClick={() => {
                                        downloadPhoto(photo);
                                        log({ screenType: "Photo Screen", action: "Download Photo" })
                                    }}
                                        color="primary">
                                        Download <DownloadRoundedIcon fontSize='large' />
                                    </button>
                                    <br />
                                </>
                            })}
                        {!demo.photos && !demo.photosName &&
                            <>
                                <ReactPlayer muted={true} light controls width="320px" height="180px" url='https://youtu.be/hDsS-IGYkPQ' />
                                <b>Experience Hairstyle within Seconds</b>
                                <br />
                                <span style={{ fontSize: '14px' }}>Step 1. Goto <u onClick={() => { updatedActiveMenu('settings'); fetchUser(); }}>Settings</u> to upload face-photo</span>
                                <br /><span style={{ fontSize: '14px' }}>Step 2. Select Hairstyle</span>
                                <br /><span style={{ fontSize: '14px' }}>Step 3. Select Customisations (haircolor/beard)</span>
                                <br />
                            </>
                        }
                        <br />
                        <br />
                    </div>
                </div>

            </LayoutAfterLogin>
        </>
    )
}

export default TryOn;