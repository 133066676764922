import React from 'react';
import { useGlobalContext } from '../../context/global_context';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ShareIcon from '@mui/icons-material/Share';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { IconButton } from '@material-ui/core';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import PersonIcon from '@mui/icons-material/Person';
import { Link } from 'react-router-dom';

async function downloadPhoto(imageUrl) {
    fetch(imageUrl, {
        "mode": "cors",
        "headers": {
            "Content-Type": "application/json",
            "access-control-allow-origin": "*",
            'Cache-Control': 'no-cache'
        }
    })
        .then(response => response.blob())
        .then(blob => {
            // Create a temporary anchor element
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;

            // Extract the filename from the URL
            const filename = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);

            // Set the download attribute and filename
            link.setAttribute('download', filename);
            document.body.appendChild(link);

            // Simulate a click on the anchor element to start the download
            link.click();

            // Clean up the temporary anchor element
            link.parentNode.removeChild(link);
        })
        .catch(error => {
            console.error('Error downloading image:', error);
        });
};

const Photo = ({ createdBy, photoId, imageAWSLink, downloadPresent, sharePresent, deletePresent }) => {

    const {
        user,
        deletePhoto,
        setLoader,
        log
    } = useGlobalContext();

    const shareFile = async () => {
        setLoader(true);
        try {
            const response = await fetch(imageAWSLink, {
                "mode": "cors",
                "headers": {
                    "Content-Type": "application/json",
                    "access-control-allow-origin": "*",
                    'Cache-Control': 'no-cache'
                }
            });

            // here image is url/location of image
            const blob = await response.blob();
            const file = new File([blob], 'share.jpg', { type: blob.type });
            setLoader(false);

            await navigator
                .share({
                    title: "Insta Photo AI",
                    text: "Generating AI portraits using awesome themes!!",
                    files: [file],
                    url: "https://instalooksai.com/photo/" + photoId
                })
                .then(() => console.log("Successful file share"))
                .catch((error) => console.log("Error in file sharing", error));
        } catch { } finally {
            setLoader(false);
        }
    }

    return (
        <div id={photoId} className="login-form-box" >
            <div className="row">
                {imageAWSLink && !Array.isArray(imageAWSLink) && <img
                    src={imageAWSLink} alt="Photo" />}
            </div>
            <div className="row">
                {(!downloadPresent || downloadPresent == true) && <div className="col-2" style={{ justifyItems: "flex-start" }}>
                    <IconButton className="btn-small" onClick={() => {downloadPhoto(imageAWSLink ? imageAWSLink : imageAWSLink); log({screenType: "Photo Screen", action: "Download Photo"})}} color="primary">
                        <DownloadRoundedIcon fontSize='large' />
                    </IconButton>
                </div>}
                {(!sharePresent || sharePresent == true) && navigator.share && <div className="col-2" style={{ justifyItems: "flex-start" }}>
                    <IconButton className="btn-small" onClick={() => {shareFile(); log({screenType: "Photo Screen", action: "Share Photo"});}} color="primary">
                        <ShareIcon fontSize='large' />
                    </IconButton>
                </div>}
                {(!deletePresent || deletePresent == true) && (createdBy === user._id) && <div className="col-2" style={{ justifyItems: "flex-start" }}>
                    <IconButton className="btn-small" onClick={() => deletePhoto(photoId)} color="primary">
                        <DeleteOutlinedIcon fontSize='large' />
                    </IconButton>
                </div>}
            </div>
            <div className="row">
                <br />
            </div>
        </div>
    )
}

export default Photo;