import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import InterestsIcon from '@mui/icons-material/Interests';
import InterestsOutlinedIcon from '@mui/icons-material/InterestsOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import GridLoader from "react-spinners/GridLoader";
import Checkbox from '@mui/material/Checkbox';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import HelpIcon from '@mui/icons-material/Help';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import LogoutIcon from '@mui/icons-material/Logout';

import {
    Link
} from "react-router-dom";
import { useGlobalContext } from '../../context/global_context';

const useStyles = makeStyles({
    sidePanelWrapper: {
        position: 'fixed',
        top: 70,
        bottom: 0,
        minWidth: '11.9%',
        maxWidth: '11.9%',
        boxShadow: '2px 2px 2px 2px #e6e6e6',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        flexWrap: 'wrap',
        padding: '15px',
    },
    sideBtn: {
        fontSize: '14px',
        fontWeight: 'bold',
        justifyContent: 'center',
        alignItems: 'center'
    },
    bottomBtn: {
        fontSize: '10px',
        fontWeight: 'bold',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    mobileBottomWrapper: {
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        boxShadow: '2px 2px 2px 2px #e6e6e6',
        top: "'" + (window.innerHeight - 60) + "px'",
        bottom: '1px',
        minHeight: '65px',
        maxHeight: '65px',
        minWidth: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        zIndex: 10,
        backgroundColor: '#fff'
    },
    innerDiv: {
        boxShadow: '0 0 4px 4px #d6d6d6',
        padding: '10px',
        color: '#000'
    }
});

const SidePanel = () => {
    const classes = useStyles();
    const {
        user,
        demo,
        isMobile,
        activeMenu,
        fetchUser,
        activeSubMenuGender,
        updatedActiveMenu,
        onFaceImageUploaded,
        removeFacePhoto,
        updatedTryOnGender,
        loading,
        uploads,
        selectUploadedImage,
        selectedPhoto,
        setRecentUploadsDisplay,
        updateSettingsChecked,
        setSkinTone,
        updatedHairColor,
        updatedBeard,
        logout
    } = useGlobalContext();

    if (activeMenu !== 'settings'
        && !demo.facePhotoLink
        && uploads.length === 0
        && (!user.settingsChecked || user.settingsChecked === false)) {
        updatedActiveMenu('settings');
    }

    return (<>
        <div className={(isMobile === false) ? classes.sidePanelWrapper : classes.mobileBottomWrapper}>
            <Grid
                container
                direction={(isMobile === false) ? "column" : "row"}
                justifyContent="center"
                alignItems='center'
            >
                <Grid item alignItems='center' justifyContent="center">
                    <Grid
                        container
                        direction={(isMobile === false) ? "column" : "row"}
                        justifyContent="center"
                        alignItems='center'
                        spacing={2}
                    >
                        <Grid item>
                            <div style={{ cursor: 'pointer' }} onClick={() => { updatedActiveMenu('settings'); fetchUser(); }} >
                                <Button className={(isMobile == false) ? classes.sideBtn : classes.bottomBtn} color="primary" startIcon={activeMenu === 'settings' ? <SettingsIcon fontSize='large' /> : <SettingsOutlinedIcon fontSize='large' />}>
                                </Button>
                                <p className={(isMobile == false) ? classes.sideBtn : classes.bottomBtn}>Settings</p>
                            </div>
                        </Grid>
                        <Grid item>
                            <Link to="/hairstyle-try-on" style={{ textDecoration: activeMenu === 'hairstyle' ? 'underline' : 'none' }} onClick={() => { updatedActiveMenu('hairstyle'); fetchUser(); }}>
                                <Button className={(isMobile == false) ? classes.sideBtn : classes.bottomBtn} color="primary" startIcon={activeMenu === 'hairstyle' ? <AddCircleOutlinedIcon fontSize='large' /> : <AddCircleOutlineOutlinedIcon fontSize='large' />}>
                                    <span style={{ color: 'red', fontSize: '8px' }}>{user.membership ? user.membership.photosCredit : 0}</span>
                                </Button>
                                <p className={(isMobile == false) ? classes.sideBtn : classes.bottomBtn}>Hair Style</p>
                            </Link>
                        </Grid>
                        {activeSubMenuGender === 'female' ? <Grid item>
                            <Link to="/hairstyle-try-on" style={{ textDecoration: activeMenu === 'haircolor' ? 'underline' : 'none' }} onClick={() => { updatedActiveMenu('haircolor'); fetchUser(); }}>
                                <Button className={(isMobile == false) ? classes.sideBtn : classes.bottomBtn} color="primary" startIcon={activeMenu === 'haircolor' ? <InterestsIcon fontSize='large' /> : <InterestsOutlinedIcon fontSize='large' />}>
                                    {user.membership
                                        && (user.membership.photosCredit > 0)
                                        && <span style={{ color: 'red', fontSize: '8px' }}>{user.membership.photosCredit}</span>}
                                </Button>
                                <p className={(isMobile == false) ? classes.sideBtn : classes.bottomBtn}>Hair Color</p>
                            </Link>
                        </Grid> : <Grid item>
                            <Link to="/hairstyle-try-on" style={{ textDecoration: activeMenu === 'beard' ? 'underline' : 'none' }} onClick={() => { updatedActiveMenu('beard'); fetchUser(); }}>
                                <Button className={(isMobile == false) ? classes.sideBtn : classes.bottomBtn} color="primary" startIcon={activeMenu === 'beard' ? <InterestsIcon fontSize='large' /> : <InterestsOutlinedIcon fontSize='large' />}>
                                    {user.membership
                                        && (user.membership.photosCredit > 0)
                                        && <span style={{ color: 'red', fontSize: '8px' }}>{user.membership.photosCredit}</span>}
                                </Button>
                                <p className={(isMobile == false) ? classes.sideBtn : classes.bottomBtn}>Beard</p>
                            </Link>
                        </Grid>}
                        <Grid item>
                            <Link to="" style={{ textDecoration: activeMenu === 'more' ? 'underline' : 'none' }} >
                                <Button className={(isMobile == false) ? classes.sideBtn : classes.bottomBtn} color="primary" onClick={() => { updatedActiveMenu('more'); fetchUser(); }} startIcon={<MoreVertIcon fontSize='large' />}>
                                </Button>
                                <p className={(isMobile == false) ? classes.sideBtn : classes.bottomBtn}>More</p>
                            </Link>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
            <Dialog maxWidth='xs' fullWidth={true} className={`sessionDialog`} open={activeMenu === 'settings'} onClose={() => updatedActiveMenu('hairstyle')}>
                <DialogTitle><b className='title' style={{ color: 'blue' }}>Settings (autosave)</b></DialogTitle>
                <DialogContent>
                    <div className={loading ? 'parentDisable' : ''} width="100%">
                        <div className='overlay-box'>
                            <GridLoader
                                color='#cc01ff'
                                loading={loading}
                                size={20} />
                        </div>
                    </div>
                    <div >
                        <div className={classes.innerDiv}>
                            <b>1/4. Gender</b>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems='flex-start'
                                spacing={3}
                            >
                                <Grid item>
                                    <div style={{ textDecoration: activeSubMenuGender === 'female' ? 'underline' : 'none', cursor: 'pointer' }} onClick={() => { updatedTryOnGender('female'); }} >
                                        <img style={{ border: activeSubMenuGender === 'female' ? '2px solid #8c52ff' : '1px solid #eee' }}
                                            src='/images/how-it-works/hair-female.png' width='60px'>
                                        </img>
                                        <br />
                                        <span className={(isMobile == false) ? classes.sideBtn : classes.bottomBtn} style={{ color: activeSubMenuGender === 'female' ? '#8c52ff' : '#9592a2' }}>Female</span>
                                    </div>
                                </Grid>
                                <Grid item>
                                    <div style={{ textDecoration: activeSubMenuGender === 'male' ? 'underline' : 'none', cursor: 'pointer' }} onClick={() => { updatedTryOnGender('male'); }} >
                                        <img style={{ border: activeSubMenuGender === 'male' ? '2px solid #8c52ff' : '1px solid #eee' }}
                                            src='/images/how-it-works/hair-male.png' width='60px'>
                                        </img>
                                        <br />
                                        <span className={(isMobile == false) ? classes.sideBtn : classes.bottomBtn} style={{ color: activeSubMenuGender === 'male' ? '#8c52ff' : '#9592a2' }}>Male</span>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        <br />
                        <div className={classes.innerDiv}>
                            <b>2/4. Face Photo</b>{(!demo.facePhotoLink || demo.facePhotoLink === undefined) ? <span style={{ color: "red", fontSize: "12px" }}>* (Not selected)</span> : <span style={{ color: "red", fontSize: "12px" }}>*</span>}
                            {uploads
                                && uploads.length > 0
                                && <>
                                    <br />
                                    <Checkbox
                                        id='recentUploadsDisplay'
                                        size='large'
                                        onClick={setRecentUploadsDisplay} // relevant method to handle your change
                                        color="primary"
                                        defaultChecked={demo.recentUploadsDisplay}
                                    />
                                    <span>Select from recently uploaded</span>
                                    <br />
                                    {demo.recentUploadsDisplay && uploads.map((photo, i) => {
                                        return <>
                                            <img
                                                key={i}
                                                onClick={() => selectUploadedImage({
                                                    index: i,
                                                    link: photo.link,
                                                    filename: photo.filename,
                                                    width: photo.width,
                                                    height: photo.height
                                                })}
                                                style={{
                                                    cursor: 'pointer',
                                                    padding: '2px',
                                                    margin: '5px',
                                                    border: (selectedPhoto
                                                        && selectedPhoto.index === i)
                                                        ? '2px solid #8c52ff' : '1px solid #eee',
                                                    width: (selectedPhoto
                                                        && selectedPhoto.index === i) ? '50px' : '40px'
                                                }}
                                                src={photo.link} />&nbsp;&nbsp;</>
                                    })}

                                    <br />
                                    <b>&nbsp;&nbsp;&nbsp;&nbsp;Or Upload New Photo</b></>}

                            <input onChange={onFaceImageUploaded} type="file" accept="image/png, image/jpeg" id="face-photo" name="face-photo" />
                            {demo.facePhotoLink && <>
                                <img style={{ padding: '10px' }} src={demo.facePhotoLink} width='60px'></img>
                                <button className='edu-btn btn-small' onClick={removeFacePhoto}>Remove Selected Photo</button>
                            </>}

                            {(!demo.facePhotoLink) && <span style={{ color: 'red', fontSize: '8px' }}><ErrorOutlineIcon />Select face-photo if want to see new look as per the face.</span>}
                        </div>
                        <br />
                        <div className={classes.innerDiv}>
                            <b>3/4. Skin Tone</b>
                            <Grid container direction='row' spacing={2}>
                                <Grid item>
                                    <img style={{ border: demo.skinTone === 1 ? '3px solid #8c52ff' : '' }} onClick={() => setSkinTone(1)}
                                        src='/images/how-it-works/settings/skin-tone/1.png' width='30px'>
                                    </img>
                                </Grid>
                                <Grid item>
                                    <img style={{ border: demo.skinTone === 2 ? '3px solid #8c52ff' : '' }} onClick={() => setSkinTone(2)}
                                        src='/images/how-it-works/settings/skin-tone/2.png' width='30px'>
                                    </img>
                                </Grid>
                                <Grid item>
                                    <img style={{ border: demo.skinTone === 3 ? '3px solid #8c52ff' : '' }} onClick={() => setSkinTone(3)}
                                        src='/images/how-it-works/settings/skin-tone/3.png' width='30px'>
                                    </img>
                                </Grid>
                                <Grid item>
                                    <img style={{ border: demo.skinTone === 4 ? '3px solid #8c52ff' : '' }} onClick={() => setSkinTone(4)}
                                        src='/images/how-it-works/settings/skin-tone/4.png' width='30px'>
                                    </img>
                                </Grid>
                                <Grid item>
                                    <img style={{ border: demo.skinTone === 5 ? '3px solid #8c52ff' : '' }} onClick={() => setSkinTone(5)}
                                        src='/images/how-it-works/settings/skin-tone/5.png' width='30px'>
                                    </img>
                                </Grid>
                            </Grid>
                            <Grid container direction='row' spacing={2}>
                                <Grid item>
                                    <img style={{ border: demo.skinTone === 6 ? '3px solid #8c52ff' : '' }} onClick={() => setSkinTone(6)}
                                        src='/images/how-it-works/settings/skin-tone/6.png' width='30px'>
                                    </img>
                                </Grid>
                                <Grid item>
                                    <img style={{ border: demo.skinTone === 7 ? '3px solid #8c52ff' : '' }} onClick={() => setSkinTone(7)}
                                        src='/images/how-it-works/settings/skin-tone/7.png' width='30px'>
                                    </img>
                                </Grid>
                                <Grid item>
                                    <img style={{ border: demo.skinTone === 8 ? '3px solid #8c52ff' : '' }} onClick={() => setSkinTone(8)}
                                        src='/images/how-it-works/settings/skin-tone/8.png' width='30px'>
                                    </img>
                                </Grid>
                                <Grid item>
                                    <img style={{ border: demo.skinTone === 9 ? '3px solid #8c52ff' : '' }} onClick={() => setSkinTone(9)}
                                        src='/images/how-it-works/settings/skin-tone/9.png' width='30px'>
                                    </img>
                                </Grid>
                                <Grid item>
                                    <img style={{ border: demo.skinTone === 10 ? '3px solid #8c52ff' : '' }} onClick={() => setSkinTone(10)}
                                        src='/images/how-it-works/settings/skin-tone/10.png' width='30px'>
                                    </img>
                                </Grid>
                            </Grid>
                        </div>
                        <br />
                        {activeSubMenuGender === 'female' &&
                            <div className={classes.innerDiv}>
                                <b>4/4. Preferred Hair Color</b>
                                <Grid container direction='row' spacing={2}>
                                    <Grid item>
                                        <img style={{ border: demo.hairCustomisation === 'black' ? '3px solid #8c52ff' : '' }} onClick={() => updatedHairColor('black')}
                                            src='/images/how-it-works/hair_catalogue/colors/black.png' width='30px'>
                                        </img>
                                    </Grid>
                                    <Grid item>
                                        <img style={{ border: demo.hairCustomisation === 'ash_brown' ? '3px solid #8c52ff' : '' }} onClick={() => updatedHairColor('ash_brown')}
                                            src='/images/how-it-works/hair_catalogue/colors/ash_brown.png' width='30px'>
                                        </img>
                                    </Grid>
                                    <Grid item>
                                        <img style={{ border: demo.hairCustomisation === 'golden_beige' ? '3px solid #8c52ff' : '' }} onClick={() => updatedHairColor('golden_beige')}
                                            src='/images/how-it-works/hair_catalogue/colors/golden_beige.png' width='30px'>
                                        </img>
                                    </Grid>
                                    <Grid item>
                                        <img style={{ border: demo.hairCustomisation === 'dark_red' ? '3px solid #8c52ff' : '' }} onClick={() => updatedHairColor('dark_red')}
                                            src='/images/how-it-works/hair_catalogue/colors/dark_red.png' width='30px'>
                                        </img>
                                    </Grid>
                                    <Grid item>
                                        <img style={{ border: demo.hairCustomisation === 'blue' ? '3px solid #8c52ff' : '' }} onClick={() => updatedHairColor('blue')}
                                            src='/images/how-it-works/hair_catalogue/colors/blue.png' width='30px'>
                                        </img>
                                    </Grid>
                                </Grid>
                            </div>}
                        {activeSubMenuGender === 'male' &&
                            <div className={classes.innerDiv}>
                                <b>4/4. Preferred Beard</b>
                                <Grid container direction='row' spacing={2}>
                                    <Grid item>
                                        <img style={{ border: demo.hairCustomisation === 'shaved' ? '3px solid #8c52ff' : '' }} onClick={() => updatedBeard('shaved')}
                                            src='/images/how-it-works/hair_catalogue/beards/shaved.png' width='50px'>
                                        </img>
                                    </Grid>
                                    <Grid item>
                                        <img style={{ border: demo.hairCustomisation === 'beard_trimmed_short' ? '3px solid #8c52ff' : '' }} onClick={() => updatedBeard('beard_trimmed_short')}
                                            src='/images/how-it-works/hair_catalogue/beards/beard_trimmed_short.png' width='50px'>
                                        </img>
                                    </Grid>
                                    <Grid item>
                                        <img style={{ border: demo.hairCustomisation === 'beard_trimmed_medium' ? '3px solid #8c52ff' : '' }} onClick={() => updatedBeard('beard_trimmed_medium')}
                                            src='/images/how-it-works/hair_catalogue/beards/beard_trimmed_medium.png' width='50px'>
                                        </img>
                                    </Grid>
                                    <Grid item>
                                        <img style={{ border: demo.hairCustomisation === 'beard_full' ? '3px solid #8c52ff' : '' }} onClick={() => updatedBeard('beard_full')}
                                            src='/images/how-it-works/hair_catalogue/beards/beard_full.png' width='50px'>
                                        </img>
                                    </Grid>
                                </Grid>
                            </div>}
                    </div>
                </DialogContent>
                <DialogActions>
                    <u className='edu-btn btn-small' style={{ color: 'blue', cursor: 'pointer', fontSize: '10px' }} onClick={() => { removeFacePhoto(); updateSettingsChecked(); updatedActiveMenu('hairstyle'); }}>Proceed without a face Photo</u>
                    <Button
                        className='edu-btn btn-small btn-secondary'
                        disabled={!demo.facePhotoLink}
                        style={{
                            opacity: (!demo.facePhotoLink) ? '30%' : "100%",
                            cursor: (!demo.facePhotoLink) ? '' : "pointer"
                        }}
                        onClick={() => { updatedActiveMenu('hairstyle'); }}
                        color="primary">
                        Virtual Try-On
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog maxWidth='xs' fullWidth={true} className={`sessionDialog`} open={activeMenu === 'more'} onClose={() => updatedActiveMenu('none')}>
                <DialogTitle><b className='title' style={{ color: 'blue' }}>More Options</b></DialogTitle>
                <DialogContent>
                    <div>
                        <Link to="/my-profile" style={{ textDecoration: activeMenu === 'ai-portrait' ? 'underline' : 'none' }} onClick={() => { updatedActiveMenu('none'); fetchUser(); }}>
                            <Button color="primary">
                                <AccountCircleIcon fontSize='large' /><b>User Profile</b>
                            </Button>
                        </Link>
                        <hr />
                        <Link to="/pricing" style={{ textDecoration: activeMenu === 'ai-portrait' ? 'underline' : 'none' }} onClick={() => { updatedActiveMenu('none'); }}>
                            <Button color="primary">
                                <LocalOfferIcon fontSize='large' /> <b>Pricing</b>
                            </Button>
                        </Link>
                        <hr />
                        <Link to="/faq" style={{ textDecoration: activeMenu === 'ai-portrait' ? 'underline' : 'none' }} onClick={() => { updatedActiveMenu('none'); }}>
                            <Button color="primary">
                                <HelpIcon fontSize='large' /><b>FAQs</b>
                            </Button>
                        </Link>
                        <hr />
                        <Link to="/contact-us" style={{ textDecoration: activeMenu === 'ai-portrait' ? 'underline' : 'none' }} onClick={() => { updatedActiveMenu('none'); }}>
                            <Button color="primary">
                                <SupportAgentIcon fontSize='large' /><b>Contact Us</b>
                            </Button>
                        </Link>
                        <hr />
                        <Link to="/" style={{ textDecoration: activeMenu === 'ai-portrait' ? 'underline' : 'none' }} onClick={() => { logout(); updatedActiveMenu('none'); }}>
                            <Button color="primary">
                                <LogoutIcon fontSize='large' /><b>Logout</b>
                            </Button>
                        </Link>
                    </div>
                </DialogContent>
                <DialogActions>
                    <span className='edu-btn btn-small' style={{ color: 'blue', cursor: 'pointer' }} onClick={() => { updatedActiveMenu('none'); }}>Close</span>
                </DialogActions>
            </Dialog>
        </div>
    </>
    )
}

export default SidePanel;
