import React from 'react';
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles';

import { useGlobalContext } from '../../context/global_context';
const hairstyles = require('../../utils/hairstyles.json');

const useStyles = makeStyles({
    actionPanelWrapper: {
        position: 'fixed',
        top: 70,
        left: '11.9%',
        bottom: 0,
        minWidth: '30%',
        maxWidth: '30%',
        boxShadow: '2px 2px 2px 2px #e6e6e6',
        alignContent: 'center',
        padding: '15px',
        overflowY: 'scroll',
    },
    sideBtn: {
        fontSize: '14px',
        fontWeight: 'bold',
        justifyContent: 'center',
        alignItems: 'center'
    },
    bottomBtn: {
        fontSize: '10px',
        fontWeight: 'bold',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    mobileBottomWrapper: {
        position: 'fixed',
        boxShadow: '0 0 4px 1px #d6d6d6',
        top: "'" + (window.innerHeight - 160) + "px'",
        bottom: '70px',
        minHeight: '170px',
        maxHeight: '170px',
        display: 'flex',
        flexDirection: 'row',
        zIndex: 9,
        backgroundColor: '#fff',
        overflowY: 'scroll',
        padding: '8px'
    }
});

const ActionPanel = () => {
    const classes = useStyles();
    const {
        path,
        demo,
        isMobile,
        activeMenu,
        activeSubMenuGender,
        updatedHairCut,
        activeHairCut,
        updatedHairCustomisation
    } = useGlobalContext();

    return (<>
        <div className={(isMobile === false) ? classes.actionPanelWrapper : classes.mobileBottomWrapper}>
            {path === '/hairstyle-try-on' &&
                activeMenu === 'hairstyle' && activeSubMenuGender !== 'male' &&
                <>
                <Grid
                    container
                    direction="row"
                    spacing={1}
                >
                    <Grid item xs={12}>
                        <u><b style={{ fontSize: '12px', width: '25px' }}>Select Hairstyle</b></u>
                    </Grid>
                    {hairstyles.female && Object.values(hairstyles.female).map((hairstyle) => {
                        return <Grid item>
                            <div style={{ textDecoration: activeHairCut === hairstyle.id ? 'underline' : 'none', cursor: 'pointer' }} onClick={() => { updatedHairCut(hairstyle.id); }} >
                                <img style={{ border: activeHairCut === hairstyle.id ? '2px solid #8c52ff' : '1px solid #eee' }}
                                    src={'/images/how-it-works/hair_catalogue/hairstyles/' + hairstyle.filename} width='110px'>
                                </img>
                                <br />
                                <span className={(isMobile == false) ? classes.sideBtn : classes.bottomBtn} style={{ color: activeHairCut === hairstyle.id ? '#8c52ff' : '#9592a2', fontSize: '10px', width: '25px' }} >{hairstyle.displayName}</span>
                            </div>
                        </Grid>
                    })}
                </Grid>
                </>
            }
            {path === '/hairstyle-try-on' &&
                activeMenu === 'hairstyle' && activeSubMenuGender !== 'female' && <Grid
                    container
                    direction="row"
                    spacing={1}>
                      <Grid item xs={12}>
                        <u><b style={{ fontSize: '12px', width: '25px' }}>Select Hairstyle</b></u>
                    </Grid>  
                    {hairstyles.male && Object.values(hairstyles.male).map((hairstyle) => {
                        return <Grid item>
                            <div style={{ textDecoration: activeHairCut === hairstyle.id ? 'underline' : 'none', cursor: 'pointer' }} onClick={() => { updatedHairCut(hairstyle.id); }} >
                                <img style={{ border: activeHairCut === hairstyle.id ? '2px solid #8c52ff' : '1px solid #eee' }}
                                    src={'/images/how-it-works/hair_catalogue/hairstyles/' + hairstyle.filename} width='110px'>
                                </img>
                                <br />
                                <span className={(isMobile == false) ? classes.sideBtn : classes.bottomBtn} style={{ color: activeHairCut === hairstyle.id ? '#8c52ff' : '#9592a2', fontSize: '10px', width: '25px' }} >{hairstyle.displayName}</span>
                            </div>
                        </Grid>
                    })}
                </Grid>
            }
            {path === '/hairstyle-try-on' &&
                activeMenu === 'haircolor' &&
                <Grid
                    container
                    direction="row"
                    spacing={1}
                >
                    <Grid item xs={12}>
                        <u><b style={{ fontSize: '12px', width: '25px' }}>Select Haircolor</b></u>
                    </Grid>
                    <Grid item>
                        <div style={{ textDecoration: demo.hairCustomisation === 'black' ? 'underline' : 'none', cursor: 'pointer' }} onClick={() => { updatedHairCustomisation('black'); }} >
                            <img style={{ border: demo.hairCustomisation === 'black' ? '2px solid #8c52ff' : '1px solid #eee' }}
                                src='/images/how-it-works/hair_catalogue/colors/black.png' width='110px'>
                            </img>
                            <br />
                            <span className={(isMobile == false) ? classes.sideBtn : classes.bottomBtn} style={{ color: demo.hairCustomisation === 'black' ? '#8c52ff' : '#9592a2', fontSize: '10px', width: '25px' }} >Black</span>
                        </div>
                    </Grid>
                    <Grid item>
                        <div style={{ textDecoration: demo.hairCustomisation === 'ash_brown' ? 'underline' : 'none', cursor: 'pointer' }} onClick={() => { updatedHairCustomisation('ash_brown'); }} >
                            <img style={{ border: demo.hairCustomisation === 'ash_brown' ? '2px solid #8c52ff' : '1px solid #eee' }}
                                src='/images/how-it-works/hair_catalogue/colors/ash_brown.png' width='110px'>
                            </img>
                            <br />
                            <span className={(isMobile == false) ? classes.sideBtn : classes.bottomBtn} style={{ color: demo.hairCustomisation === 'ash_brown' ? '#8c52ff' : '#9592a2', fontSize: '10px', width: '25px' }} >Ash Brown</span>
                        </div>
                    </Grid>
                    <Grid item>
                        <div style={{ textDecoration: demo.hairCustomisation === 'golden_beige' ? 'underline' : 'none', cursor: 'pointer' }} onClick={() => { updatedHairCustomisation('golden_beige'); }} >
                            <img style={{ border: demo.hairCustomisation === 'golden_beige' ? '2px solid #8c52ff' : '1px solid #eee' }}
                                src='/images/how-it-works/hair_catalogue/colors/golden_beige.png' width='110px'>
                            </img>
                            <br />
                            <span className={(isMobile == false) ? classes.sideBtn : classes.bottomBtn} style={{ color: demo.hairCustomisation === 'golden_beige' ? '#8c52ff' : '#9592a2', fontSize: '10px', width: '25px' }} >Golden Beige</span>
                        </div>
                    </Grid>
                    <Grid item>
                        <div style={{ textDecoration: demo.hairCustomisation === 'dark_red' ? 'underline' : 'none', cursor: 'pointer' }} onClick={() => { updatedHairCustomisation('dark_red'); }} >
                            <img style={{ border: demo.hairCustomisation === 'dark_red' ? '2px solid #8c52ff' : '1px solid #eee' }}
                                src='/images/how-it-works/hair_catalogue/colors/dark_red.png' width='110px'>
                            </img>
                            <br />
                            <span className={(isMobile == false) ? classes.sideBtn : classes.bottomBtn} style={{ color: demo.hairCustomisation === 'dark_red' ? '#8c52ff' : '#9592a2', fontSize: '10px', width: '25px' }} >Dark Red</span>
                        </div>
                    </Grid>
                    <Grid item>
                        <div style={{ textDecoration: demo.hairCustomisation === 'blue' ? 'underline' : 'none', cursor: 'pointer' }} onClick={() => { updatedHairCustomisation('blue'); }} >
                            <img style={{ border: demo.hairCustomisation === 'blue' ? '2px solid #8c52ff' : '1px solid #eee' }}
                                src='/images/how-it-works/hair_catalogue/colors/blue.png' width='110px'>
                            </img>
                            <br />
                            <span className={(isMobile == false) ? classes.sideBtn : classes.bottomBtn} style={{ color: demo.hairCustomisation === 'blue' ? '#8c52ff' : '#9592a2', fontSize: '10px', width: '25px' }} >Blue</span>
                        </div>
                    </Grid>
                </Grid>
            }
            {path === '/hairstyle-try-on' &&
                activeMenu === 'beard' &&
                <Grid
                    container
                    direction="row"
                    spacing={1}
                >
                    <Grid item xs={12}>
                        <u><b style={{ fontSize: '12px', width: '25px' }}>Select Beard</b></u>
                    </Grid>
                    <Grid item>
                        <div style={{ textDecoration: demo.hairCustomisation === 'shaved' ? 'underline' : 'none', cursor: 'pointer' }} onClick={() => { updatedHairCustomisation('shaved'); }} >
                            <img style={{ border: demo.hairCustomisation === 'shaved' ? '2px solid #8c52ff' : '1px solid #eee' }}
                                src='/images/how-it-works/hair_catalogue/beards/shaved.png' width='110px'>
                            </img>
                            <br />
                            <span className={(isMobile == false) ? classes.sideBtn : classes.bottomBtn} style={{ color: demo.hairCustomisation === 'shaved' ? '#8c52ff' : '#9592a2', fontSize: '10px', width: '25px' }} >Shaved</span>
                        </div>
                    </Grid>
                    <Grid item>
                        <div style={{ textDecoration: demo.hairCustomisation === 'beard_trimmed_short' ? 'underline' : 'none', cursor: 'pointer' }} onClick={() => { updatedHairCustomisation('beard_trimmed_short'); }} >
                            <img style={{ border: demo.hairCustomisation === 'beard_trimmed_short' ? '2px solid #8c52ff' : '1px solid #eee' }}
                                src='/images/how-it-works/hair_catalogue/beards/beard_trimmed_short.png' width='110px'>
                            </img>
                            <br />
                            <span className={(isMobile == false) ? classes.sideBtn : classes.bottomBtn} style={{ color: demo.hairCustomisation === 'beard_trimmed_short' ? '#8c52ff' : '#9592a2', fontSize: '10px', width: '25px' }} >Trimmed (short)</span>
                        </div>
                    </Grid>
                    <Grid item>
                        <div style={{ textDecoration: demo.hairCustomisation === 'beard_trimmed_medium' ? 'underline' : 'none', cursor: 'pointer' }} onClick={() => { updatedHairCustomisation('beard_trimmed_medium'); }} >
                            <img style={{ border: demo.hairCustomisation === 'beard_trimmed_medium' ? '2px solid #8c52ff' : '1px solid #eee' }}
                                src='/images/how-it-works/hair_catalogue/beards/beard_trimmed_medium.png' width='110px'>
                            </img>
                            <br />
                            <span className={(isMobile == false) ? classes.sideBtn : classes.bottomBtn} style={{ color: demo.hairCustomisation === 'golden_beige' ? '#8c52ff' : '#9592a2', fontSize: '10px', width: '25px' }} >Trimmed (medium)</span>
                        </div>
                    </Grid>
                    <Grid item>
                        <div style={{ textDecoration: demo.hairCustomisation === 'beard_full' ? 'underline' : 'none', cursor: 'pointer' }} onClick={() => { updatedHairCustomisation('beard_full'); }} >
                            <img style={{ border: demo.hairCustomisation === 'beard_full' ? '2px solid #8c52ff' : '1px solid #eee' }}
                                src='/images/how-it-works/hair_catalogue/beards/beard_full.png' width='110px'>
                            </img>
                            <br />
                            <span className={(isMobile == false) ? classes.sideBtn : classes.bottomBtn} style={{ color: demo.hairCustomisation === 'beard_full' ? '#8c52ff' : '#9592a2', fontSize: '10px', width: '25px' }} >Full</span>
                        </div>
                    </Grid>
                </Grid>
            }
        </div>
    </>
    )
}

export default ActionPanel;
