import React from 'react'
import Candidate from '../../components/profile/Candidate'
import SEO from '../../common/SEO';
import LayoutAfterLogin from '../../common/LayoutAfterLogin';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import { useGlobalContext } from '../../context/global_context';

function Profile() {
    const {
        path,
        updatePath
    } = useGlobalContext();

    if (path != '/my-profile') {
        updatePath('/my-profile');
    }

  return (
    <>
        <SEO title="InstaLooksAI User" />
            <LayoutAfterLogin>
                <BreadcrumbOne 
                    title="My Profile"
                    parentUrl="Home"
                    currentUrl="My Profile"
                />
                <div className="login-register-page-wrapper edu-section-gap bg-color-white">
                    <div className="container checkout-page-style">
                        <div className="row">
                            <div className="col-12">
                                <Candidate/>
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutAfterLogin>
    </>
    
  )
}

export default Profile