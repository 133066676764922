import { useState } from 'react';
import { Link } from 'react-router-dom';
import HeaderSticky from './HeaderSticky';
import { useGlobalContext } from '../../context/global_context';
import Button from '@material-ui/core/Button';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

const HeaderOne = ( { styles, disableSticky } ) => {
    const {
        user,
        activeMenu,
        updatedActiveMenu,
        fetchUser,
        setNeedLogin
    } = useGlobalContext();

    const sticky = HeaderSticky( 118 );
    const classes = sticky ? 'sticky' : '';
    const stickyStatus = disableSticky ? '' : ' header-sticky';
    return (
        <>
            <header className={`edu-header disable-transparent ${ stickyStatus } ${ styles || '' } ${ classes || '' }`}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-xl-2 col-md-6 col-6">
                            <div className="logo">
                                <Link to={(user && user.token) ? "/hairstyle-try-on":"/"}>
                                    <img className='logo-rocket' src="/images/logo/rocket.png"/>
                                    <img className="logo-light" src="/images/logo/logo.png" alt="Main Logo" width="60%" />
                                </Link>
                            </div>
                        </div>

                        <div className="col-lg-8 d-none d-xl-block">
                            <nav className="mainmenu-nav d-none d-lg-block">
                            </nav>
                        </div>

                        <div className="col-lg-6 col-xl-2 col-md-6 col-6">
                            <div className="header-right d-flex justify-content-end">
                                <div className="header-quote">
                                    <div className="quote-icon quote-user">
                                    { user.token 
                                    ? <Link to="/my-profile" style={{ textDecoration: activeMenu === 'register' ? 'underline' : 'none' }}>
                                        <Button onClick={() => {updatedActiveMenu('profile'); fetchUser();}} color="primary" startIcon={activeMenu === 'profile' ? <AccountCircleIcon fontSize='large'/>: <AccountCircleOutlinedIcon fontSize='large'/>}>
                                        </Button>
                                        </Link>
                                    : <span className='edu-btn btn-small' style={{color: 'blue'}} onClick={() => setNeedLogin(true)}>Login</span>
                                    }                              
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default HeaderOne;