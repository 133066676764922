import React, { useState, useContext } from 'react';
import { Accordion } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
 
const faqCategories = [
    {
        id: 1,
        slug: 'ai-face-photos',
        label: 'AI Photos'
    },
    {
        id: 2,
        slug: 'payment-method',
        label: 'Payment Method'
    },
    {
        id: 3,
        slug: 'pricing-plan',
        label: 'Pricing Plan'
    }
];

const AIPhotos = {
    leftColumn: [
        {
            id: 1,
            title: 'What is an AI generated photo?',
            body: 'An AI-generated photo refers to an image that has been created or altered using artificial intelligence (AI) techniques. These techniques involve the use of machine learning algorithms, specifically deep learning models, to generate or modify images based on patterns and data learned from a training dataset. These images are mostly unique and we have trained our datasets to provide beautiful stunning high quality images'
        },
        {
            id: 2,
            title: 'Will there be a copyrights on using these photos?',
            body: 'No. As these images are machine generated, they are unique images created by only taking inspirations from the already existing images in our dataset.'
        },
        {
            id: 3,
            title: 'Where all can I use these photos?',
            body: 'AI generated images are getting widely used at social networking, social media, ecommerce and blogging websites. Influencers are using AI generated images to keep the enagement high in their social networks. Similarly, ecommerce stores are using these images on their marketing campaigns. So basically linkedIn, Instagram, Blogs, ECommerce stores, ads and marketing are some applications of AI generated images.'
        }
    ],
    rightColumn: [
        {
            id: 1,
            title: 'Can I edit these photos?',
            body: 'You can download and edit these images as you wish. But we currently don\'t provide feature to edit it directly on InstaLooksAI.'
        },
        {
            id: 2,
            title: 'Can I share these photos?',
            body: 'Yes, you can freely share these images.'
        },
        {
            id: 3,
            title: 'Who all can use these photos?',
            body: 'AI generated photos can be used by anyone. It is currently widely used by instagram users, influencers, marketers, sales executives and store managers.'
        }
    ]
}

const paymentMethod = {
    leftColumn: [
        {
            id: 1,
            title: 'Is There Any One To One Support System?',
            body: 'We made our platform simple to use. If get any issue, you can connect to our support at +917093003840.'
        },
        {
            id: 2,
            title: 'Do You Provide Photo Generation Guideline?',
            body: 'On InstaLooksAI, you can generate photos with minimum discription. Still we also provide manual prompt functionality to add more details to the photos.'
        },
        {
            id: 3,
            title: 'How do I get a refund?',
            body: 'We provide 1 free photo daily on our daily-theme. This helps user to understand the power of InstaLooksAI. Still if you have paid but not very happy with our service, we can fix this. A big portion of your payment can be refunded, depending on the subscription days. You can connect to support at +917093003840.'
        }
    ],
    rightColumn: [
        {
            id: 1,
            title: 'How do I cancel my subscription?',
            body: 'We try our best to provide fun and colorful way to provide AI generated image. Still if you are not very happy with the subscription, you can connect to our support at +917093003840 and we will try to fix it.'
        },
        {
            id: 2,
            title: 'Is there an auto-renewable of subscription?',
            body: 'No. You need to manually renew your subscription if about to end.'
        },
        {
            id: 3,
            title: 'How many people can share the subscription?',
            body: 'Subscription cannot be shared by many people. Still if you share your credentials with others then the photo generation credits will be reduced as usual.'
        }
    ]
}

const pricingPlan = {
    leftColumn: [
        {
            id: 1,
            title: 'What are the pricing plans present for InstaLooksAI?',
            body: 'We have 3 pricing plans - Silver, Gold and Platinum. You can find more details on the Pricing Page'
        },
        {
            id: 2,
            title: 'What is the benefit of taking yearly plan?',
            body: 'Yearly plans provide the similar benefits as the respective monthly plan. Only difference is the yearly plan comes at a discounted rate. Thus help you save money if taken an yearly subscription. Gold yearly subscription is our most popular plan.'
        },
        {
            id: 3,
            title: 'Is there any coupon to get discount on the plans?',
            body: 'Currently there is no coupon. We do have periodic offers and all the users are informed about it through email or WhatsApp'
        }
    ],
    rightColumn: [
        {
            id: 1,
            title: 'Is the daily free theme photo, free for always?',
            body: 'Daily free photo will always be FREE, irrespective if you are a paid member or not. Any user can get one free beautiful photo each day from InstaLooksAI.'
        },
        {
            id: 2,
            title: 'How many photos will I get for free from Daily themes?',
            body: 'This a new free theme everyday. Each user gets 1 free photo using this theme.'
        },
        {
            id: 3,
            title: 'How long is my personal free trial?',
            body: 'Personal free trial will always exist. If you are very happy with the free trial, try our paid plans to generate many images by giving very simple descriptions.'
        }
    ]
}
        
const CustomToggle = ({ children, eventKey }) => {
    const { activeEventKey } = useContext( AccordionContext );
    const decoratedOnClick = useAccordionButton( eventKey );
    const isCurrentEventKey = activeEventKey === eventKey;
    return <button type="button" onClick={ decoratedOnClick } aria-expanded={ isCurrentEventKey ? true : false } className="edu-accordion-button">{ children }</button>
}

const Faq = ( { wrapperClass } ) => {
    const [selectedItem, setSelectedItem] = useState( 'ai-face-photos' );
    const [activeId1, setActiveId1] = useState( '0' );
    const [activeId2, setActiveId2] = useState( '0' );
    const [activeId3, setActiveId3] = useState( '0' );
    const [activeId4, setActiveId4] = useState( '0' );
    const [activeId5, setActiveId5] = useState( '0' );
    const [activeId6, setActiveId6] = useState( '0' );

    function toggleActive1( id ) {
        if (activeId1 === id) {
            setActiveId1(null);
        } else {
            setActiveId1(id);
        }
    }
    function toggleActive2( id ) {
        if (activeId2 === id) {
            setActiveId2(null);
        } else {
            setActiveId2(id);
        }
    }
    function toggleActive3( id ) {
        if (activeId3 === id) {
            setActiveId3(null);
        } else {
            setActiveId3(id);
        }
    }
    function toggleActive4( id ) {
        if (activeId4 === id) {
            setActiveId4(null);
        } else {
            setActiveId4(id);
        }
    }
    function toggleActive5( id ) {
        if (activeId5 === id) {
            setActiveId5(null);
        } else {
            setActiveId5(id);
        }
    }
    function toggleActive6( id ) {
        if (activeId6 === id) {
            setActiveId6(null);
        } else {
            setActiveId6(id);
        }
    }

    return (
        <>
            <SEO title="Frequently Asked Questions" />
            <Layout>
                <BreadcrumbOne 
                    title="Frequently Asked Questions"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="FAQ"
                />
                <div className={ `edu-accordion-area accordion-shape-1 edu-section-gap bg-color-white ${ wrapperClass ? wrapperClass : '' } ` }>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="pre-title">FaQ Questions</span>
                                    <h3 className="title">Get Every General Answers <br /> From Here</h3>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <ul className="edu-tab-nav nav nav-tabs mt--40">
                                    {
                                        faqCategories.map( ( button ) => (
                                            <li className="nav-item" key={ button.id }>
                                                <button
                                                    className={ button.slug === selectedItem ? 'nav-link active' : 'nav-link'}
                                                    type="button"
                                                    aria-label={button.label}
                                                    onClick={ () => setSelectedItem( button.slug ) }
                                                >
                                                    { button.label }
                                                </button>
                                            </li>
                                        ) )
                                    }
                                </ul>
                            </div>
                        </div>
                        { selectedItem === 'ai-face-photos' && 
                            <div className="row g-5 align-items-center mt--20">
                                <div className="col-lg-6">
                                    <div className="accordion-style-1">
                                        <Accordion bsPrefix="edu-accordion" defaultActiveKey="0" flush>
                                            {
                                                AIPhotos.leftColumn.map( ( accordion, i ) => (
                                                    <Accordion.Item eventKey={i.toString()} key={i} onClick={() => toggleActive1(i.toString())} bsPrefix={`edu-accordion-item ${activeId1 === i.toString() ? 'active-item' : ''}`}>
                                                        <div className="edu-accordion-header">
                                                            <CustomToggle eventKey={i.toString()}>{accordion.title}</CustomToggle>
                                                        </div>
                                                        <Accordion.Body bsPrefix="edu-accordion-body">{accordion.body}</Accordion.Body>
                                                    </Accordion.Item>
                                                ) )
                                            }
                                        </Accordion>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="accordion-style-1">
                                        <Accordion bsPrefix="edu-accordion" defaultActiveKey="0" flush>
                                            {
                                                AIPhotos.rightColumn.map( ( accordion, i ) => (
                                                    <Accordion.Item eventKey={i.toString()} key={i} onClick={() => toggleActive2(i.toString())} bsPrefix={`edu-accordion-item ${activeId2 === i.toString() ? 'active-item' : ''}`}>
                                                        <div className="edu-accordion-header">
                                                            <CustomToggle eventKey={i.toString()}>{accordion.title}</CustomToggle>
                                                        </div>
                                                        <Accordion.Body bsPrefix="edu-accordion-body">{accordion.body}</Accordion.Body>
                                                    </Accordion.Item>
                                                ) )
                                            }
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        }

                        { selectedItem === 'payment-method' && 
                            <div className="row g-5 align-items-center mt--20">
                                <div className="col-lg-6">
                                    <div className="accordion-style-1">
                                        <Accordion bsPrefix="edu-accordion" defaultActiveKey="0" flush>
                                            {
                                                paymentMethod.leftColumn.map( ( accordion, i ) => (
                                                    <Accordion.Item eventKey={i.toString()} key={i} onClick={() => toggleActive3(i.toString())} bsPrefix={`edu-accordion-item ${activeId3 === i.toString() ? 'active-item' : ''}`}>
                                                        <div className="edu-accordion-header">
                                                            <CustomToggle eventKey={i.toString()}>{accordion.title}</CustomToggle>
                                                        </div>
                                                        <Accordion.Body bsPrefix="edu-accordion-body">{accordion.body}</Accordion.Body>
                                                    </Accordion.Item>
                                                ) )
                                            }
                                        </Accordion>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="accordion-style-1">
                                        <Accordion bsPrefix="edu-accordion" defaultActiveKey="0" flush>
                                            {
                                                paymentMethod.rightColumn.map( ( accordion, i ) => (
                                                    <Accordion.Item eventKey={i.toString()} key={i} onClick={() => toggleActive4(i.toString())} bsPrefix={`edu-accordion-item ${activeId4 === i.toString() ? 'active-item' : ''}`}>
                                                        <div className="edu-accordion-header">
                                                            <CustomToggle eventKey={i.toString()}>{accordion.title}</CustomToggle>
                                                        </div>
                                                        <Accordion.Body bsPrefix="edu-accordion-body">{accordion.body}</Accordion.Body>
                                                    </Accordion.Item>
                                                ) )
                                            }
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        }

                        { selectedItem === 'pricing-plan' && 
                            <div className="row g-5 align-items-center mt--20">
                                <div className="col-lg-6">
                                    <div className="accordion-style-1">
                                        <Accordion bsPrefix="edu-accordion" defaultActiveKey="0" flush>
                                            {
                                                pricingPlan.leftColumn.map( ( accordion, i ) => (
                                                    <Accordion.Item eventKey={i.toString()} key={i} onClick={() => toggleActive5(i.toString())} bsPrefix={`edu-accordion-item ${activeId5 === i.toString() ? 'active-item' : ''}`}>
                                                        <div className="edu-accordion-header">
                                                            <CustomToggle eventKey={i.toString()}>{accordion.title}</CustomToggle>
                                                        </div>
                                                        <Accordion.Body bsPrefix="edu-accordion-body">{accordion.body}</Accordion.Body>
                                                    </Accordion.Item>
                                                ) )
                                            }
                                        </Accordion>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="accordion-style-1">
                                        <Accordion bsPrefix="edu-accordion" defaultActiveKey="0" flush>
                                            {
                                                pricingPlan.rightColumn.map( ( accordion, i ) => (
                                                    <Accordion.Item eventKey={i.toString()} key={i} onClick={() => toggleActive6(i.toString())} bsPrefix={`edu-accordion-item ${activeId6 === i.toString() ? 'active-item' : ''}`}>
                                                        <div className="edu-accordion-header">
                                                            <CustomToggle eventKey={i.toString()}>{accordion.title}</CustomToggle>
                                                        </div>
                                                        <Accordion.Body bsPrefix="edu-accordion-body">{accordion.body}</Accordion.Body>
                                                    </Accordion.Item>
                                                ) )
                                            }
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Faq;