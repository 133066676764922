import React from 'react'
import { useGlobalContext } from '../../context/global_context';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Photo from '../photos/Photo';
import PullToRefresh from 'react-simple-pull-to-refresh';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';


async function downloadBinaryPhoto(imageBinary) {
    try {
        // Create a temporary anchor element
        // const url = window.URL.createObjectURL("data:image/png;base64," + imageBinary);
        const link = document.createElement('a');
        link.href = "data:image/png;base64," + imageBinary;

        // Extract the filename from the URL
        const filename = "instaLooksAI_output_" + Date.now() + ".png";

        // Set the download attribute and filename
        link.setAttribute('download', filename);
        document.body.appendChild(link);

        // Simulate a click on the anchor element to start the download
        link.click();

        // Clean up the temporary anchor element
        link.parentNode.removeChild(link);
    } catch (ex) {
        console.log("Error in downloading an image", ex.message)
    }
};

function Candidate() {
    const {
        user,
        logout,
        setLoader,
        updatedActiveMenu,
        onAccountTypeChanged,
        updateFollowerRequest,
        fetchUser,
        log
    } = useGlobalContext();

    const customPhotosReverse = user.customPhotos ? user.customPhotos.map((item, idx) => user.customPhotos[user.customPhotos.length - 1 - idx]) : [];
    const themePhotosReverse = user.themePhotos ? user.themePhotos.map((item, idx) => user.themePhotos[user.themePhotos.length - 1 - idx]) : [];
    

    let pendingFollowerRequests = [];
    

    const handleRefresh = async() => {
        setLoader(true);
        fetchUser();
        setLoader(false);
    }

    return (

        <PullToRefresh 
                onRefresh={handleRefresh} 
                pullDownThreshold='10'
                backgroundColor='#fff'
                refreshingContent={<b style={{color:'blue'}}>Reloading...</b>}
                >
        <div className="login-form-box" style={{minWidth:'300px'}}>
            <div className="profile-form">

                <div className="input-box mb--20">
                    <label class="form-check-label">Email:</label>
                    <text type="email" id="email">  {user.email}</text>
                </div>
                
                <div className="input-box mb--20">
                    <label class="form-check-label">Photos Generated:</label>
                    <text type="email" id="email">{user.customPhotos ? user.customPhotos.length : 0}</text>
                </div>

                <div className="input-box mb--20">
                    <label class="form-check-label">PhotoCredits:</label>
                    <text type="email" id="email">  {user.membership?.photosCredit}</text>
                </div>
                {/* <div className="input-box mb--20">
                    <label class="form-check-label">ModelCredits:</label>
                    <text type="email" id="email">  {user.membership?.modelCredit}</text>
                    <span>      </span>
                    <Link onClick={() => updatedActiveMenu('model')} to="/make-my-model" className="edu-btn btn-small" >Create My Model</Link>
                </div> */}
                <div className="input-box mb--20">
                <Link to="/pricing">
                    <Button className="edu-btn btn-small btn-secondary"> Check Pricing</Button>
                </Link>
                </div>

                <div className="input-box mb--20">
                    <Button onClick={logout} className="edu-btn btn-small btn-alt btn-white" >Logout</Button>
                </div>
                {/* <div className="input-box mb--6">
                    <label class="form-check-label">Theme Photos Generated:</label>
                    <text type="email" id="email">{user.themePhotos ? user.themePhotos.length : 0}</text>
                </div>
                {user.themePhotos &&
                    <div className="row g-3">
                        {themePhotosReverse.map((photo) => {
                            if (!photo.deleted || photo.deleted == false) {
                                return <div className="col-12 col-md-3">
                                    <Photo modelPrompt={photo.modelPrompt} createdBy={photo.createdBy} prompt={photo.prompt} visibleForAll={photo.visibleForAll} photoId={photo._id} themeKey={photo.themeKey} reusedCount={photo.reusedCount} imageSDLink={photo.imageSDLink} likePresent='false' />
                                </div>
                            } else {
                                <></>
                            }
                        })}
                    </div>
                } */}
                {user.customPhotos &&
                    <div className="row g-3">
                        {customPhotosReverse.map((photo) => {
                            if (photo.content && (!photo.deleted || photo.deleted == false)) {
                                return <div className="col-12 col-md-3">
                                    {/* <Photo modelPrompt={photo.modelPrompt} createdBy={photo.createdBy} prompt={photo.prompt} visibleForAll={photo.visibleForAll} photoId={photo._id} themeKey={photo.themeKey} reusedCount={photo.reusedCount} imageSDLink={photo.imageSDLink} likePresent='false' /> */}
                                    <img style={{ border: '1px solid #000' }} src={`data:image/png;base64,${photo.content}`} alt="Generated Images" />
                                <br />
                                <button className="edu-btn btn-secondary btn-small" onClick={() => {
                                    downloadBinaryPhoto(photo.content);
                                    log({ screenType: "Photo Screen", action: "Download Photo" })
                                }}
                                    color="secondary">
                                    Download <DownloadRoundedIcon fontSize='large' />
                                </button>
                                <br/>
                                
                                </div>
                            } else { <></> }
                        })}
                    </div>
                }
            </div>
        </div>
        </PullToRefresh>
    )
}

export default Candidate