import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import LayoutAfterLogin from '../../common/LayoutAfterLogin';
import BannerFour from '../../components/banner/BannerFour';
import WhyUs from '../../components/home-five/WhyUs';
import HairstylesPhotoCreation from '../../components/home-four/HairstylesPhotoCreation';
import HairColorsPhotoCreation from '../../components/home-four/HairColorsPhotoCreation';
import { useGlobalContext } from '../../context/global_context';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Divider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import GridLoader from "react-spinners/GridLoader";


const HomeFour = () => {
    const {
        user,
        path,
        updatePath,
        needLogin,
        setNeedLogin,
        updateRegisterEmail,
        updatePassword,
        submit,
        googleSignIn,
        loading
    } = useGlobalContext();

    if (user && user.token) {
        window.location.href = "/hairstyle-try-on";
    } else if (path != '/') {
        updatePath('/')
    }

    return (
        <>
            <SEO title="InstaLooksAI" />

            {(!user || !user.token) ?
                <Layout>
                    <BannerFour />
                    <HairstylesPhotoCreation />
                    <HairColorsPhotoCreation />
                    <WhyUs />

                    <Dialog maxWidth='xs' fullWidth={true} className={`sessionDialog`} open={needLogin} onClose={() => setNeedLogin(false)}>
                        <DialogTitle><b className='title' style={{ color: 'blue' }}>Need To Login/Register</b></DialogTitle>
                        <DialogContent>
                            <div className={loading ? 'parentDisable' : ''} width="100%">
                                <div className='overlay-box'>
                                    <GridLoader
                                        color='#cc01ff'
                                        loading={loading}
                                        size={20} />
                                </div>
                            </div>
                            <div className="edu-feature-list color-variation-1">
                                <div className="mb--10 row">
                                    <button className="edu-btn btn-white mb--10" onClick={googleSignIn}><img src="/images/logo/g-logo.jpeg" /> Sign in with Google 🚀 </button>
                                </div>
                                <div className="login-form">
                                    <div className="mb--10 row align-text-center">
                                        <br /><Divider />
                                    </div>
                                    <div className="mb--10 row">
                                        <input type="email" placeholder="Email" onChange={updateRegisterEmail} />
                                    </div>
                                    <div className="row mb--10">
                                        <input type="password" placeholder="Password" onChange={updatePassword} />
                                    </div>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <span className='edu-btn btn-small' style={{ color: 'blue' }} onClick={() => setNeedLogin(false)}>Close</span>
                            <Button className='edu-btn btn-small' onClick={submit}
                                color="primary">
                                Submit
                            </Button>
                        </DialogActions>
                    </Dialog>

                </Layout>
                :
                <LayoutAfterLogin />
            }
        </>
    )
}

export default HomeFour;